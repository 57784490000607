// variables
$brand-primary-color: #72a7ff;
$brand-primary-color: #2e7dff;
$brand-secondary-color: #191919;
$brand-border-color: #9db5d2;
$border-radius: 50px;
$control-height: 35px;

ngb-modal-backdrop {
  z-index: 1040 !important;
}

.btn {
    font-family: "Nirmala UI", sans-serif;
}
.btn--medium {
    width: 160px;
}
.btn--round {
    border-radius: 30px;
}
.btn--bordered {
    border: 2px solid $brand-border-color;
    border-radius: 30px;
    font-size: 15px;
    height: auto;
    text-align: center;
}
.btn--filled {
    background-color: #08a2de;
    border-radius: 20px;
    border-style: none;
    color: #fff;
    cursor: pointer;
    height: auto;
    text-transform: uppercase;
}
.btn-primary {
    background-color: #08a2de;
}
.btn-round {
    border-radius: 30px;
    border: none;
}

// mixins
@mixin btn-add($border-color: $brand-primary-color, $dimension: 50px) {
    background: transparent;
    border: 2px solid $border-color;
    border-radius: 50%;
    cursor: pointer;
    height: $dimension;
    width: $dimension;

    a {
        border: none;
        color: #767676;
        display: block;
        font-size: 40px;
        line-height: 40px;
        margin: 0;
        padding: 0;
        text-align: center;
        transition: all 0.3s;
        // position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &:hover, :active, :focus {
            text-decoration: none;
        }
    }
}

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    top: calc(50% - 60px);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Colors
$color-brand-primary: #005aaa;
$color-brand-secondary: #00baf2;
$color-primary: #08a2de;
$color-secondary: #9db5d2;

$color-info: #3498db;
$color-success: #2ecc71;
$color-warning: #f39c12;
$color-danger: #e74c3c;

$color-link: #2980b9;
$color-link--visited: #2980b9;
$color-link--hover: #3498db;
$color-link--active: #3498db;

$color-text: #222;
$color-text--dimmed: #7f8c8d;
$color-text--inversed: #fff;

$base-url: '/assets/';

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #619cff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spinner 1.2s linear infinite;
}

#app-container {
  overflow: scroll!important;
}
// select.form-control{
//     border: 2px solid $brand-border-color;
//     border-radius: $border-radius;
//     text-align: center;
//     margin: 0 auto;
//     font-size: 15px;
//     height: 35px !important;
//     text-align-last:center;
//     text-align:center;
// }
